// ==========================================================================
// Global Typography
// ==========================================================================
html,
body {
    font-family: @font-primary;
    font-size: @font-size;
    line-height: @line-height;
    color: @color-text;
}

// Clearfix
.clearfix:before, .clearfix:after {
    content: "";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    zoom: 1;
} // IE7

// Font Style
b, strong, .strong {
    &:extend(.font-bold);
}
i, em, .em {
    font-style: italic;
}

// Links
a { 
    color: @color-link; 
    text-decoration: none;
    &:hover {
        color: darken(@color-link, 10%);
    }
    &:focus {
        outline: thin dotted;
    }
    &:active, &:hover {
        outline: 0;
    }
}

// Paragraph and Small
p {
    margin: 0 0 1.6em 0;
    padding: 0 !important;
}
pre {
    margin: 1em 0;
}
ul + p, ul + pre, ol + p, ol + pre {
    margin-top: 0;
}

// Abbreviations
abbr[title] {
    border-bottom: 1px dotted;
}

// Mark
mark {
    background: #ff0;
    color: #111;
}

// Audio
audio, canvas, video {
    &:extend(.inline-block);
}
audio:not([controls]) {
    &:extend(.none);
    height: 0;
}

// Small, Sub and Sup
small {
    font-size:80%;
}
sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}

// Definition
dfn {
    &:extend(.em);
}

// Images
img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}

// Horizontal Rule
hr {
    border: 0;
    background: none;
    outline: 0;
    background-color: #ddd;
    margin: 1em 0;
    *margin: 1em 0;
    height: 1px;
}

// Headings
h1, h2, h3, h4, h5, h6 {
    font-family: @font-secondary;
    line-height: normal;
    font-weight: 700;
    margin: 0 0 0.33em 0;
}
h1, .h1 {
    font-size: 175%;
}
h2, .h2 {
    font-size: 150%;
}
h3, .h3 {
    font-size: 140%;
    font-weight: 700;
}
h4, .h4 {
    font-size: 115%;
    font-weight: 500;
}
h5, .h5 {
    font-size: 100%;
}
h6, .h6 {
    font-size: 90%;
}

// Remove Heading Styling
.no-style-heading {
    &:extend(.no-margin);
    font-size: 100%;
}