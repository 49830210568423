// ==========================================================================
// Global Resets
// ==========================================================================
*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}
html, button, input, select, textarea {
    font-family: sans-serif;
}
body, form, fieldset, legend, input, select, textarea, button {
    margin: 0;
}
html {
    font-size: 100%;
}