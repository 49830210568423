.sticky {
    position: fixed !important;
    right: 0;
    left: 0;
    top: 0 !important;
    z-index: 999;
    opacity: 0.95;
    .wsmenu.slideLeft .wsmenu-list::before {
        background-image: url("../images/logo.png");
        background-repeat: no-repeat;
        background-size: 45px auto;
        content: "";
        float: left;
        height: 35px;
        position: relative;
        top: 12px;
        left: 30px;
        width: 58px;
    }
}
.overlapblackbg {
    display: none;
}
.wsmenuexpandermain {
    position: relative;
    display: none;
}
.no-mobile.wsmenu-arrow.fa.fa-angle-down {
    font-weight: 700;
    padding-left: 3px;
}
.wsmenu {
    font-family: @font-tertiary;
    color: @color-navbar-text;
    position: relative;
    font-size: @font-size + 3;
    font-weight: 700;
    padding: 0px;
    margin: 0px auto;
    width: 100%;
    &-click {
        display: none;
    }
    &-list {
        text-align: left;
        margin: 0 auto 0 auto;
        width: 100%;
        display: table;
        padding: 0px;
        & > li {
            text-align: center;
            display: table-cell;
            & > a {
                display: block;
                color: @color-navbar-text;
                padding: 0px 14px;
                line-height: 48px;
                border-right: 1px solid rgba(0,0,0,0.10);
                text-decoration: none;
                position: relative;
                white-space: nowrap;
                .fa {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 0;
                    margin-right: 0px;
                }
            }
            & > a:hover, & > a.active {
                background-color: lighten(@color-navbar, 10%)!important;
                text-decoration: none;
                color: #fff ;
            }
        }
        li {
            a {
                .arrow:after {
                    border-left: 4px solid rgba(0, 0, 0, 0);
                    border-right: 4px solid rgba(0, 0, 0, 0);
                    border-top: 4px solid #b3b3b3;
                    content: "";
                    float: right;
                    height: 0;
                    margin: 0 0 0 9px;
                    position: absolute;
                    text-align: right;
                    top: 22px;
                    width: 0;
                }
                &:hover, &.active {
                    .arrow:after {
                        border-top-color:#b3b3b3;
                    }
                }
            }
            &:hover {
                & > a {
                    background-color: darken(@color-navbar, 10%);
                    text-decoration: none;
                    color: #fff;
                    .arrow:after {
                        border-top-color:#b3b3b3;
                    }
                }
                & > ul {
                    .transform(rotateX(0deg));
                    opacity: 1;
                    visibility: visible;
                    display: block;
                }
            }
            & > ul {
                .transform-style(preserve-3d);
                .transform(rotateX(-75deg));
                visibility: hidden;
            }
        }
        ul {
            position: absolute;
            top:48px;
            z-index: 1000;
            margin: 0px;
            padding: 0px;
            background-color: darken(@color-navbar, 10%);
            opacity: 0;
            .transform-origin(0% 0%);
            .trans-prefix(0.3s, opacity 0.3s);
            & li > ul, & ul li > ul ul {
                .transform-style(preserve-3d);
                .transform(rotateX(-75deg));
                visibility: hidden;
            }
            & li:hover > ul,  & ul li:hover > ul ul {
                .transform(rotateX(0deg));
                opacity: 1;
                visibility: visible;
            }
            & > li > a:hover {
                background-color:  lighten(@color-navbar, 10%);
                border-radius: 0px!important;
                text-decoration: none;
            }
            .fa {
                margin-right: 7px;
            }
            li {
                position: relative;
                padding: 0px;
                margin: 0px;
                display: block;
                a {
                    background: darken(@color-navbar, 10%);
                    background-image: none !important;
                    color: #fff !important;
                    border-right: 0 none !important;
                    text-align: left;
                    display: block;
                    line-height: 22px;
                    padding: 6px 12px;
                    text-transform: none;
                    font-size: 16px;
                    letter-spacing: normal;
                    border-right: 0px solid;
                }
            }
          ul, ul ul {
                min-width: 220px;
                position: absolute;
                left: 100%;
                top: 0;
                margin: 0px;
                padding: 0px;
                opacity: 0;
                .transform-origin(0% 0%);
                .trans-prefix(0.4s, opacity 0.4s);
            }
            & li:hover ul, & ul li:hover ul {
                opacity: 1;
                list-style: none;
                padding: 2px;
                display: block;
            }
            & ul li:hover ul {
                border: solid 1px darken(@color-navbar, 10%);
                background-color: darken(@color-navbar, 10%);
            }
        }
    }
}
@media only screen and (min-width:@mobile-breakpoint) and (max-width:(@tablet-breakpoint + 242)) {
    .wsmenu {
        font-size: 13px !important;
        &-list {
            > li > a > .fa {
                //display: none !important;
            }
            li a {
                white-space: nowrap !important;
                padding-left: 4px !important;
                padding-right: 4px !important;
            }
        }
    }
}
@media only screen and (min-width: @tablet-breakpoint) and (max-width:(@tablet-breakpoint + 419)) {
    .wsmenu-list li a .arrow:after {
        display: none !important;
    }
}
@media only screen and (min-width: @tablet-breakpoint) {
    .wsmenu-list li:hover > ul, .wsmenu-list ul li:hover > ul, .wsmenu-list ul ul li:hover > ul {
        display: block !important;
    }
}
/*------------------------------------ Mobile CSS ---------------------------------------------*/
@media only screen and (max-width: @mobile-breakpoint) {
    .wsmenucontainer {
        overflow: hidden;
        min-height: 800px;
        .blackface-visibility(hidden);
        .transition(all 0.04s ease-in-out);
    }
    .mrginleft {
        margin-left: 240px;
        .transition(all 0.04s ease-in-out);
        .callusicon, .mapicon, .devider {
            display: none !important;
        }
    }

    .sticky {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        height: 0;
        width: 0;
        .wsmenuexpandermain .animated-arrow.menuopen {
            left: 240px !important;
            margin-top: 4px !important;
        }
        .animated-arrow, .smallogo, .callusicon, .devider, .mapicon {
            position: fixed;
        }
        .smallogo {
            right: 0;
            img { 
                .opacity(1.0); 
                max-height: 27px;
                width: auto;
                &.menuopen {
                    margin-left: 240px;
                }
            }
        }
        .wsmenu.slideLeft .wsmenu-list {
            width: initial;
            &:before {
                display: none;
            }
        }
    }
    .overlapblackbg {
        left: 0;
        z-index: 100;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        display: none;
        background-color: rgba(0, 0, 0, 0.45);
        cursor: pointer;
        &.menuopen {
            display: block;
        }
    }
    .wsmenuexpandermain {
        display: block !important;
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        .transition(all 0.04s ease-in-out);
        z-index: 900;
        .menuopen {
            left: -15px !important;
            margin-top: 0px !important;
            padding: 10px 35px 29px 10px;
            background-color: #000;
        }
    }
    .wsmenu {
    	position: relative;
    	width: 0px;
    	height: 100%;
    	position: fixed;
    	left: 0;
    	top: 0;
    	margin: 0;
    	background-color: #fff;
    	border-radius: 0px;
    	z-index: 100;
    	.transition(all 0.04s ease-in-out);
    	border: none ;
    	background-color: #fff ;
        &.menuopen {
            width:240px;
            .transition(all 0.04s ease-in-out);
            .m-nav {
                display: block !important;
                height: 60px;
                width: 240px;
                > div a {
                    border: 1px solid #ffffff;
                    float: left;
                    padding: 22px 20px;
                }
            }
        }
        &.menuclose {
            .transition(all 0.04s ease-in-out);
        }
    	&-list > li:hover > a {
    		background-color: rgba(0, 0, 0, 0.08);
    		text-decoration: none;
    	}
    	&-list li:first-child a, &-list li:last-child a {
    		.border-radius( 0px 0px 0px 0px );
    	}
    	&-list li:last-child a {
    		border-right: 0px solid;
    	}
    	&-list > li > a .fa {
    		margin-right: 6px;
    	}
    	&-list ul > li:hover > a {
    		background-color: #7b7b7b;
    		color: #fff;
    	}
    	> .wsmenu-list > li > a.active, > .wsmenu-list > li > a:hover {
    		color: #fff;
    		background-color: rgba(0, 0, 0, 0.08);
    	}
    	> .wsmenu-list > li > a {
    		padding: 9px 32px 9px 17px;
    		font-size: 14px;
    		text-align: left;
    		border-right: solid 0px;
    		background-color: transparent;
    		color: #666666;
    		line-height: 25px;
    		border-bottom: 1px solid;
    		border-bottom-color: rgba(0, 0, 0, 0.13);
    		position: static;
    		> .fa {
    			font-size: 16px;
    			color: #bfbfbf;
    		}
    	}
    	.wsmenu-list {
    		height: 100%;
    		overflow-y: auto;
    		display: block;
    		> li {
    			width: 240px;
    			display: block;
    			float: none;
    			border-right: none;
    			background-color: transparent;
    			position: relative;
    			white-space: inherit;
    		}
    		li a .arrow:after {
    			display: none ;
    		}
    		li ul li a .fa.fa-caret-right {
    			font-size: 12px;
    			color: #8E8E8E;
    		}
    	}
    	&-list li:hover ul {
    		display: none;
    		ul {
    			display: none;
    		}
    	}
    	&-list ul ul li:hover ul {
    		display: none;
    		list-style: none;
    		padding: 2px;
    		border: solid 1px #eeeeee;
    		background-color: #fff;
    	}
    	&-click {
    		border-left: 1px solid rgba(0, 0, 0, 0.13);
    		cursor: pointer;
    		display: block;
    		height: 43px;
    		padding-left: 6px;
    		position: absolute;
    		right: 0;
    		top: 0;
    		i {
    			display: block;
    			height: 23px;
    			width: 25px;
    			margin-top: 11px;
    			margin-right: 8px;
    			background-size: 25px;
    			font-size: 21px;
    			color: rgba(0, 0, 0, 0.25);
    			float: right;
    		}
    		&:hover {
    			background-color: rgba(0, 0, 0, 0.13);
    		}
    	}
    	&-rotate {
    		.transform (rotate(180deg)) !important;
    	}
    	&-list ul ul {
    		width: 100%;
    		position: static;
    		left: 100%;
    		top: 0;
    		display: none;
    		margin: 0px;
    		padding: 0px;
    		border: solid 0px;
    		transform: none;
    		opacity: 1;
    		visibility: visible;
    		li {
    			margin: 0px 0px 0px 0px;
    			padding: 0px;
    			position: relative;
    			&.active {
    				color: #000 ;
    			}
    			&:hover > a {
    				background-color: #333333;
    				color: #fff;
    			}
    		}
    		a {
    			display: block;
    			padding: 10px 25px 10px 25px;
    			border-bottom: solid 1px #ccc;
    			font-weight: normal;
    			&.active {
    				color: #000 ;
    			}
    		}
    		ul {
    			width: 100% ;
    			position: static ;
    			left: 100% ;
    			top: 0 ;
    			display: none;
    			margin: 0px ;
    			padding: 0px ;
    			border: solid 0px ;
    			transform: none ;
    			opacity: 1 ;
    			visibility: visible ;
    			li {
    				margin: 0px 0px 0px 0px ;
    				&.active {
    					color: #000 ;
    				}
    				&:hover > a {
    					background-color: #606060 ;
    					color: #fff;
    				}
    			}
    			a {
    				display: block;
    				color: #000;
    				padding: 10px 25px;
    				background: #000 ;
    				border-bottom: solid 1px #ccc;
    				font-weight: normal;
    				&.active {
    					color: #000 ;
    				}
    			}
    		}
    	}
    	&-list li > ul, &-list li:hover > ul, &-list ul li > ul {
    		.transform (none) !important;
    		visibility: visible ;
    	}
    	&-list ul li:hover > ul, &-list ul ul li > ul, &-list ul ul li:hover > ul {
    		.transform (none) !important;
    		visibility: visible ;
    		opacity: 1  ;
    	}
    	&-list > li > a .fa {
    		display: none;
    	}
}
    .mobile-sub ul {
    	transform: none ;
    	opacity: 1 ;
    	display: none;
    	position: relative ;
    	top: 0px ;
    	background-color: #fff;
    	border: solid 1px #ccc;
    	padding: 0px;
    	visibility: visible ;
    	li a {
    		line-height: 20px;
    		height: 36x;
    		background-color: #e7e7e7 !important;
    		font-size: 13px ;
    		padding: 8px 0px 8px 18px;
    		color: #8E8E8E !important;
    		&:hover {
    			background-color: #e7e7e7;
    			color: @color-primary !important;
    			text-decoration: underline;
    		}
    	}
    	li:hover > a {
    		background-color: #e7e7e7 ;
    		color: #fff;
    	}
    	li ul li a, li ul li ul li a {
    		line-height: 20px;
    		height: 36x;
    		background-color: #e7e7e7 ;
    		border-bottom: none;
    		padding-left: 28px;
    	}
    	li ul li ul li a {
    		padding-left: 38px;
    		color: #8e8e8e;
    	}
    }
    .wsmenucontent {
        z-index:1;
    }
    .animated-arrow {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 200;
        .transition(all 0.04s ease-in-out);
        cursor: pointer;
        padding: 13px 35px 16px 0px;
        margin: 10px 0 0 15px;
        span:before {
            top: -7px;
        }
        span:after {
            bottom: -7px;
        }
        & span, & span:before, & span:after {
            cursor: pointer;
            height: 3px;
            width: 23px;
            background: #c9c9c9;
            position: absolute;
            display: block;
            content: '';
            .transition(all 0.04s ease-in-out);//500ms
        }
        &.menuopen {
            span {
                background-color: transparent;
		        &:before {
                	.transform (rotate(45deg));
            	    bottom: 0px;
        	    }
        	    &:after {
                .transform (rotate(-45deg));
                }
	        }
        }
        &.menuopen span:before, &.active span:after {
            top: 7px;
        }
    }
    .callusicon {
        color: #c9c9c9;
        font-size: 25px;
        height: 25px;
        position: fixed;
        right: 15px;
        top: 2px;
        transition: all 0.04s ease-in-out 0s;
        width: 25px;
        z-index: 2;
        .transition(all 0.04s ease-in-out);
        &:focus, &:hover {
            color: #fff !important;
        }
    }
    .mapicon {
        color: #c9c9c9;
        font-size: 20px;
        height: 25px;
        position: fixed;
        right: 53px;
        top: 2px;
        transition: all 0.04s ease-in-out 0s;
        width: 25px;
        z-index: 2;
        .transition(all 0.04s ease-in-out);
        &:focus, &:hover {
            color: #fff !important;
        }
    }
    .devider {
        color: #c9c9c9;
        font-size: 22px;
        position: fixed;
        right: 45px;
    }
    .mrginleft .callusicon, .mrginleft .mapicon, .mrginleft .devider {
        display: none !important;
    }
    .smallogo {
        width: 100%;
        display: block;
        text-align: center;
        padding-top: 9px;
        position: fixed;
        z-index: 0;
        width: 100%;
        height: 41px;
        background: @color-navbar;
        background: -moz-linear-gradient(top, @color-navbar 0, darken(@color-navbar, 10%) 50%, @color-navbar 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, @color-navbar), color-stop(50%, darken(@color-navbar, 10%)), color-stop(100%, @color-navbar));
        background: -webkit-linear-gradient(top, @color-navbar 0, darken(@color-navbar, 10%) 50%, @color-navbar 100%);
        background: -o-linear-gradient(top, @color-navbar 0, darken(@color-navbar, 10%) 50%, @color-navbar 100%);
        background: -ms-linear-gradient(top, @color-navbar 0, darken(@color-navbar, 10%) 50%, @color-navbar 100%);
        background: linear-gradient(to bottom, @color-navbar 0, darken(@color-navbar, 10%) 50%, @color-navbar 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=@color-navbar, endColorstr=@color-navbar, GradientType=0);
        #shadow > .box-shadow (0px, 1px, 4px, rgba(0,0,0,0.5));
        .opacity(0.9);
        img {
            .opacity(0.0);
        }
    }
}