// ==========================================================================
// Buttons
// ==========================================================================
.button {
    .border-radius(4px);
    &:extend(.text-center);
    &:extend(.inline-block);
    .transition(.05s all ease);
    background: @color-link;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    color: #fff;
    font-family: @font-primary;
    padding: 5px 8px;
    margin: 1em 0.5em;
    &:hover {
        background: lighten(@color-link, 5%);
        color: #fff;
    }
    &[disabled], &.disabled {
        color: #999;
        background-color: #f1f1f1;
    }
    i {
        margin-right: 4px;
    }
}