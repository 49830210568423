/*Grid*/
.grid {
    max-width: @desktop-container !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: @container-padding;
    padding-right: @container-padding;
    position: relative;
}

// ==========================================================================
// Desktop Custom Styles
// ==========================================================================


/****************************
*   FRAMEWORK OVERRIDES
*****************************/
.grid { max-width: @max-content-width !important;}
.ccms-contentelement { margin-bottom: 0px; }
.sticky {opacity: 1;}

/****************************
*   BASE
*****************************/
*, *:before, *:after { box-sizing: border-box; }
html, body { width: 100%; min-height: 100%; margin: 0; padding: 0; position:relative; /*overflow-x:hidden;*/ font-size: @font-size !important; vertical-align:baseline;}
html {height: 100%; }
body { position: relative;}
/* This was removed to make footer more flexible
main {padding-bottom: 290px;}
footer{height: 290px; position: absolute; bottom: 0; left: 0; background: #FFF; width: 100%; overflow: hidden;}
*/
::selection { background: @color-primary; color: #FFF;}
::-moz-selection { background: @color-primary; color: #FFF;}

/****************************
*   EDITOR FIXES
*****************************/
.rspPane {
    main {padding-bottom: 0 !important;}
    footer { position: relative !important; }
}

/****************************
*   UNIVERSAL
*****************************/
.media-state {
    display: block; 
    position: absolute; 
    z-index: 3; 
    visibility: hidden; 
    height: 0; 
    width: 0; 
    top: 0; 
    left: -9999px;
}

.ada {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    color:#000;
    background: #fff;
}

#skiptocontent a {
    background: #bf1722 none repeat scroll 0 0;
    border-bottom: 1px solid #ffffff;
    border-bottom-right-radius: 8px;
    border-right: 1px solid #ffffff;
    background: @color-body;
    color: #ffffff;
    left: 0;
    padding: 6px;
    position: absolute;
    top: -40px;
    transition: top 1s ease-out 0s, background 1s linear 0s;
    z-index: 100;
}
#skiptocontent a:focus {
    background: #bf1722 none repeat scroll 0 0;
    content: #ffffff;
    left: 0;
    outline: 0 none;
    position: absolute;
    top: 0;
    transition: top 0.1s ease-in 0s, background 0.5s linear 0s;
}

.page-title {
    position: relative;
    color: contrast(#fff, @color-primary, @color-tertiary);
    margin-bottom: 30px;
    
    span.page-desc {display: block; font-weight: 400; font-size: 0.875rem; color: #666; font-family: @font-primary;}
    &:after {content: ""; display: block; height: 1px; border-bottom: 1px solid #DDD; width: 40%; min-width: 200px; padding-top: 10px;}
}
.catapultTitle {
    font-family: @font-secondary; 
    color: @color-secondary; 
    font-size: 1.75rem; 
    font-weight: 400; 
    text-transform: uppercase;
    padding-top: 30px;
    line-height: 1;

    &.enabledIcon {
        .ctIconLeft {
            width: 1.5rem;
            height: 1.5rem;
            top: -1px;
            display: inline-block;
            vertical-align: middle;
            background-size: cover;
            position: relative;
            margin-right: 0.375rem;
        }
    }
    
    &.disabledIcon {
        .ctIconLeft {display: none;}
    }
}

.breadcrumbs { 
    background: #f5f5f5; 
    font-size: 14px; 
    color: #000; 
    font-family: @font-secondary; 
    font-weight: 300; 
    letter-spacing: 1px;
    border-bottom: 1px solid @color-line;
    padding: 10px 0px;
    
    
    a { color: inherit;
        &:hover, b {color: @color-secondary;}
    }
    
    .breadcrumb-quick-links {
        text-align: right;
        a 
        { 

            display: inline-block;
            position: relative;
            color: #FFF;
            background: @color-body;
            height: 60px;
            width: 60px;
            border-radius: 50%;
            text-align: center;
            font-size: 21px;
            line-height: 60px;
            -webkit-font-smoothing: antialiased;
            cursor: pointer;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -ms-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
            margin: 0 20px 25px;
            vertical-align: top;
            text-transform: uppercase;
            padding: 1px 4px;

            i {display: inherit;}
            
            &:after {
                content: attr(data-label);
                display: block;
                font-family: 'Oswald', Arial, sans-serif;
                text-transform: uppercase;
                font-weight: 300;
                font-size: 14px;
                line-height: 1;
                margin: 8px 0 0 -15px;
                width: 90px;
                color: #005890;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}

.grid-contain {position: relative; height: 100%;}

.round-link {
    display: inline-block;
    position:relative;
    color: #FFF;
    background: #000;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 28px;
    line-height: 60px;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;
    .transition();

    &:hover {
        .transform(scale(1.1));
        background: @color-secondary;
        color: contrast(@color-secondary, #111, #FFF); 
    }
}



.home-col-contrast {
    position:relative;
    
    .catapultTitle {
        font-size: 60px;
    }
    
}

.home-col-narrow,
.home-col-wide {
    padding-top: 40px;
    padding-bottom: 40px;
    float: left;
    position: relative;
    
    & > div {
        display: inline-block;
        text-align: left;
    }
}

.home-col-wide {
    position:relative;
    padding-bottom: 0px;
    font-size: 15px;
    .clearfix;
    text-align: right;
    
    &:before {
        content: "";
        display: block; position: absolute;
        border-right:1px solid @color-primary;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .24);
        /*background: url(../images/logo-transparent.png) no-repeat top 20px right 40px;*/
        top: 0;
        right: 0;
        width: 9999px;
        height: 9999px;
    }
    
    & > div {
        width: 100%;
        position: relative;
    }
    
    .catapultTitle {
        color: @color-primary;
    }

}
.home-col-narrow {
    position:relative;
    
    .catapultTitle{padding-left:10px;}
    &:before{
        content: "";
        display: block;
        border-left:1px solid @color-primary;    
        position: absolute;
        top: 0;
        left: 0;
        width: 9999px;
        height: 9999px;
    }
    
    & > div {
        width: 100%;
        position: relative;
    }
}

//Search
.search {
    position: relative;
    margin-left: 30px;
    float: right;

    & > a {
        display: inline-block; 
        color: contrast(@color-body, #fff, #111); 
        height: 36px;
        line-height: 36px;
        text-align: center;
        font-size: 0.6875rem;
        text-transform: uppercase;
        vertical-align: middle;
        outline: none;
        border: none;

        i {
            color:@color-secondary;
            font-size: 1rem;
            margin-right: 10px;
            vertical-align: middle;
            margin-top: -3px;
        }


//        &:focus, &:hover {
//            & + div {display: block !important;}
//        }

        &.open + div {display: block !important;}
        
        &:hover i { 
            color: @color-secondary;
        }
    }

    #m2 { 
        display: none;
        position: absolute;
        white-space: nowrap;
        right: 0;
        padding: 5px;
        background: @color-primary;
        border-radius: 2px;
        top: 100%;

        &:hover { display: block !important; }

        &:before {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-bottom: 6px solid @color-primary;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            top: -6px;
            position: absolute;
            right: 20px;
        }

        input {
            display: inline-block;
            height: 34px;
            border: none;
            width: 190px;
            vertical-align: bottom;
        }

        button {
            display: inline-block;
            height: 34px;
            background: #FFF;
            color: #666;
            border: none;
            vertical-align: bottom;
        }

        input:focus, button:focus {border: none; outline: none;}

    }

}

/****************************
*   NAVBAR
*****************************/
.navbar {   
    @navbar-lighten: lighten(@color-navbar, 2%); 
    background: @color-navbar;
    position: relative;

    &:before{
        .absolute-full-bleed();
        content: "";
        display: block;
        background: -moz-linear-gradient(top, @navbar-lighten 50%, @color-navbar 50%);
        background: -webkit-linear-gradient(top, @navbar-lighten 50%,@color-navbar 50%);
        background: linear-gradient(to bottom, @navbar-lighten 50%,@color-navbar 50%);   
        filter: ~"progid:DXImageTransform.Microsoft.gradient( startColorstr='@{navbar-lighten}', endColorstr='@{color-navbar}',GradientType=0 )";
    }
    
    /* Web Slide */
    .wsmenu-list  {
        & > li { position: relative;}
        & > li > ul {min-width: 100%;}
        & > li > a { text-transform: uppercase; font-size: 0.9375rem; border-right: none; padding: 0 10px; color: contrast(@color-navbar, #111, #FFF);}
        
        & > li:hover > a {background: transparent; color: #333;}
        & > li > a:hover, & > li > a.active { background-color: transparent !important;}
        
        ul li {
            border-bottom: 0.0625rem solid #4E4E4E;
            
            a {
                background: #2D2D2D;
                font-size: 0.8125rem;
                font-weight: 300;
                font-family: @font-primary;
                color: #FFF;
                
                .fa {margin-left: 0.625rem;}
            }
        }
        
        ul ul li:first-child:before  {content: " "; display: block; width: 0; height: 0; border-right: 6px solid #888; border-top: 6px solid transparent; border-bottom: 6px solid transparent; position: absolute; left: -6px; top: 50%; .transform(translateY(-50%));}
  
        ul li:hover ul, 
        ul ul li:hover ul { padding: 0; border: none;}
        
        ul ul {border-left: 0.0625rem solid #888 !important;}
        
        ul li a:hover { color: #FFF !important; background: #424242;}
        
        &:before {
            display: none !important;
        }
    }
}



/****************************
*   HEADER
*****************************/
.header {
    position:relative;
    background: #555 url(../images/header-bg.jpg) center / cover no-repeat;
    
    &:before {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        
        background: fade(@color-body, 85%);
    }
}


/****************************
*   TOP BAR
*****************************/
.mobile-quick-bar {display: none; 
    .quick-links {border-top: 1px solid #DEDEDE;}
}

.top-bar {
    height:36px;
    color: #FFF;
    
    //Language/Schools
    .language,
    .schools {
        position: relative;
        margin-top: 5px;
        float: left;
        
        & > a {display: inline-block; color: contrast(@color-body, #fff, #111); height: 30px; font-size: 0.6875rem; padding: 0 5px; line-height: 26px; text-transform: uppercase; outline: none;
            .fa {font-size: 0.8125rem; line-height: inherit; color: contrast(@color-body, #fff, #111); margin-left: 5px;}
            
            //&:focus, 
//            &:hover {
//                & + div {display: block !important;}
//            }
            
            
            &.open + div {display: block !important;}
            
        }
        
        
        
        &:hover {
            & > a .fa {color: @color-secondary;}
        }
        
        .drop-container {
            position: absolute;
            display: none;
            width: 160px;
            font-size: 0.875rem;
            background: @color-primary;
            color: contrast(@color-primary, #111, #FFF);
            top: 100%;
            border-radius: 2px;
            
            &:hover {display: block !important;}
            
            &:before {
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-bottom: 6px solid @color-primary;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                top: -6px;
                position: absolute;
                left: 20px;
            }
            
            a {display: block; padding:2px 5px; color: inherit;
                &:hover {background: lighten(@color-primary, 5%); }
            }
        }
    }
    
    .language {margin-right: 5px; margin-left: 26px;
        &:before {content: " "; width: 1px; height: 20px; background: contrast(@color-body, #fff, #111); position: absolute; left: -13px; top: 4px;}
    }

    
    
    
    //Social
    .top-bar-social {
        float: right;
        height: 36px;
        margin-left: 20px;
        line-height: 36px;
        font-size: 0.625rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.03125rem;
        
        a {text-decoration: none; color: inherit; outline: none;
            &:hover {
                color: @color-link;
                .fa {color: @color-secondary;}
            }
        }
        
        a+a {margin-left: 20px;}
            
        .fa {
            font-size: 1.25rem; 
            color: @color-link; 
            vertical-align: middle;
        }
        
    }
    
}

/****************************
*   TITLE BAR
*****************************/
.title-bar {
    position:relative;
    min-height: 100px;
    height: 170px;
       
    .logo,
    .title-links {
        position: absolute;
        width: 200px;
        height: 150px;
        z-index: 10;
        
        a {
            position:relative;
            border: none;
            outline: none;
            z-index: 4; 
            top:50%;
            .transform(translateY(-50%));
        }
    }   
    
     .logo {
        left: 0;
        
        a { display: block;
            top: 50%;
        }
        a img {
            display: block;
            max-width: 100%;
            max-height:inherit;
            width: auto;
            height: auto;
        }
    }
    
    .title-links {
        right: 0;
        text-align:right;
        
        & > a {
            .round-link;
            background: @color-secondary;
            top: 40%;
            height: 50px;
            width: 50px;
            font-size: 25px;
            line-height: 48px;
            
            &:hover {
                .transform(scale(1.1) translateY(-50%));
                
                span {  
                    display: inline-block;
                    position: absolute;
                    top: 100%;
                    font-size: 12px;
                    line-height: 1.4;
                    color: contrast(@color-secondary, #FFF, #000);
                    white-space: nowrap;
                    right: 0;
                    background: @color-secondary;
                    padding: 5px;
                    border-radius: 1px;
                    font-family:  @font-secondary;
                    letter-spacing: 1px;
                    opacity: 1; 
                    margin-top: 10px; 
                    -webkit-animation: 0.2s 1 normal showIt;
                                animation: 0.2s 1 normal showIt;

                    &:before {
                        display: block; 
                        position: absolute;
                        content: " ";
                        top: -5px;
                        right: 24px;
                        height: 0;
                        width: 0;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 6px solid @color-secondary;
                    }
                }
            }
            
            span { display: none; }
        }
        
        a+a { margin-left: 10px;}
        
    }
    
    .site-title {
        position:absolute;
        text-align: center;
        height:140px;
        padding-left: 200px;
        padding-right: 200px;
        z-index:9;
        width: 100%;
        
        h1 {
            position:relative;
            top:50%;
            .transform(translateY(-50%));
            
            a{
                text-transform: uppercase;
                color: contrast(@color-body, @color-primary, @color-tertiary);
                font-weight: 400;
                font-size: 90px;
                margin: 0;
                line-height: 1;
                padding-bottom: 10px;
                span {
                    display: block;
                    color: contrast(@color-body, #fff, #111);
                    font-weight: 300;
                    font-size: 24px;
                    margin-top: 10px;
                }
                &:hover{text-decoration: none;}

            }
            
            a.teacherpage span {
                text-transform: uppercase;
                color: @color-tertiary;
                font-weight: 400;
                font-size: 90px;
                margin: 0;
                line-height: 1;
                padding-bottom: 10px;
            }
            
        }   
    }
}

@keyframes showIt {
    0% { display: none; opacity: 0; margin-top: 20px; }
    1% { display: inline-block; opacity: 0; }
    100% { opacity: 1; margin-top: 10px; }   
}

@-webkit-keyframes showIt {
    0% { display: none; opacity: 0; margin-top: 20px; }
    1% { display: inline-block; opacity: 0; }
    100% { opacity: 1; margin-top: 10px; }  
}


/****************************
*   QUICK LINKS
*****************************/
.mobile-quick-links {
    display: none;
    position:relative;
    text-align: center;
    margin-top: 20px; 
    
    a {
        .round-link;
        height: 40px;
        width: 40px;
        line-height: 40px;
        font-size: 18px;
        vertical-align: top;
        margin-bottom: 25px;
        margin-right: 5%;
        
        &:after {
            content: attr(data-label);
            display: block;
            font-size: 10px;
            text-align: center;
            line-height: 1;
            margin-top: 4px;
            height: 25px;
            color: #FFF;
        }
        
    }
    a+a {margin-left: 13px;}
}


/****************************
*   BANNER
*****************************/

.banner {
    position: relative;
    .homepages & {height: 0; padding-bottom: 30vw;}
    .subpages & {height: auto;}
    
    .ccmsBanner { position: absolute; top: 0; left: 0; height: 100%; width: 100%;
        .homepages & {height: 100%;}
        .subpages & {height: 100%;}
    }
    
    
    .no-js & {
        .ccms-banner {display: none;}
        .ccms-banner:first-child {display: block; height: 100%; width: 100%; position: relative;}
    }
}


/* Video Banner */
.video-banner {
    background: #f9f9f9 url(@pattern-square-dark);
    border-bottom: 1px solid #EEE;
    border-top: 1px solid #EEE;
    position: relative;
    overflow: hidden;
    
   .grid {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .ccms-contentelement {margin-bottom: 0;}
    .bg-vid-blocker {position: absolute; background: transparent; left: 0; right: 0; top: 0; bottom: 0; z-index: 9;}
     #bg-vid { display: none; opacity: 0; position: absolute; min-width: 100%; min-height: 100%; width: 100%; height: auto; overflow: hidden; z-index: 8;}
    .bg-vid-container{ height: 0; padding-bottom: 56.25%; width: 100%; position: relative; overflow: hidden;}
    
    /* Video Only */
    &.video-contained {
        .bg-vid-container{ position: relative; }
    }
   
    /* Full Width + Content Overlay */
    &.video-full {
        .bg-vid-container{  position: absolute; top: 50%; left: 50%; .transform(translateX(-50%) translateY(-50%)); }
        .video-content { 
            background: fade(@color-secondary, 85%);
            color: contrast(@color-primary, #333, #FFF); 
            padding: 35px; 
            margin: auto;
        }
        .grid {padding-top: 200px; padding-bottom: 200px;}
    }
}



/****************************
*   CONTENT AREA
*****************************/
/* Primary Content */

main{overflow: hidden} /*crop contrast bg*/

.primary-quick-links {
    background: @color-body;
    padding: 20px 0;
    text-align: center;
    a {
        .round-link;
        margin: 0 20px 25px;
        vertical-align: top;
        background: @color-secondary;
        
        &:after {
            content: attr(data-label);
            display: block;
            font-family: @font-secondary;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 14px;
            line-height: 1;
            margin: 8px 0 0 -15px;
            width: 90px;
            color: contrast(@color-body, #fff, #111);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            
        }
    }
}

.primary-quick-links.teacher {
    border-bottom: 20px solid @color-primary;
}

.link-tiles {
    background: @color-primary url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAOUlEQVQoU2P8//+/FCMj4zMGHAAsD5LDpRAmDlaETSGyRrgiZIXoJqMowmU1aSahW4HhJoK+IyacAP5iQjj6uJumAAAAAElFTkSuQmCC);
    
    .ccms-contentelement {
        margin-bottom: 0;
    }
    
    ul.tiles {
        margin: 0 auto;
        list-style-type: none;
        max-width: 1020px;
        height: 360px;
        
        li {
            border:1px solid #fff;
            position:relative;
            display: block;
            width: 33.333%;
            height: 50%;
            float:left;

            a {
                display: block;
                position: relative; 
                font-size: 24px;
                line-height: 1.3;
                color: #FFF;
                font-family: @font-secondary;
                text-transform: uppercase;
                height: 100%;
                overflow: hidden;

                &:hover {
                    div {
                        .transform(scale(1.1));

                        &:before {
                            background: fade(@color-primary, 10%);
                        }
                    }
                }

                div {
                    position:relative;
                    background-size: cover;
                    background-position: center;
                    height:100%;
                    .transition();

                    &:before {
                        position: absolute;
                        content: " ";
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(33, 33, 33, 0.6);
                        z-index: 0;
                        .transition();
                    }
                }

                span {
                    display: block;
                    position: absolute; 
                    bottom: 15px;
                    left: 15px;
                    #shadow > .text-shadow(1px, 1px, 2px, 1);
                }
            }
        }  
    } 
}




/*  Parallax Window */
.rspPane .ccms-templatecontent .parallax-window {
    background: #CCC;
}
.parallax-window .ccms-contentelement {
    margin-bottom: 0;
}


/****************************
*   SIDE NAV (SUB PAGES)
*****************************/
.sidenav {
    border: 0.0625rem solid #ededed;
    padding: 0.0625rem;
    background-color: #fff;
    margin: 0.5rem 0 1.5rem;
    
    & > ul > li {
        & > a {
            background-color: @color-secondary;
            color: contrast(desaturate(darken(@color-secondary, 6%), 4%), #222, #FFF);
            font-size: 0.875rem;
            text-align: left;
            padding: 0.4375rem 0.625rem;
            text-shadow: none;
            margin: 0;
            font-weight: 300;
            text-transform: uppercase;
            
            &:hover {background: @color-secondary; color: #FFF;}
        }
        
        & > span { display: none !important;}
        
    } 
    
    & > ul > li > ul > li > a {
        padding-left: 0.625rem;
    }
    
    & > ul > li > ul > li > ul > li > a {
        padding-left: 1.5625rem;
    }
    
    & > ul > li > ul > li > ul > li > ul > li > a {
        padding-left: 2.5rem;
    }

    
    /* All ULs */
    ul {
        list-style-type: none;
        padding: 0;
        margin:0;
    }
    
    /* All LIs */
    li {
        position:relative;
        border-top: 0.0625rem solid #e0e0e0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: #fafafa;
        
        a { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            font-weight: 400;
            padding: 0.25rem 0.25rem 0.25rem 1.25rem;
            color: @color-text;
            
            &:hover {background: #f0f0f0; color: #000;}
            
            i {font-style: normal !important;}
        }
    }
    
    
    .sidenav-sub .side-active {
        & > a {
            background: fade(@color-secondary, 30%);
            color: #000;
            &:hover {background: fade(@color-secondary, 40%);}
        }
        & > ul {display:block;}
//        & > ul a {background: fade(@color-secondary, 20%);
//            &:hover {background: fade(@color-secondary, 30%); color: #000;}
//        }
    }
    
    
    &>ul>li>ul li{
        &>ul {display: none;}
        
        a {
            height:2rem; 
            line-height: 2rem; 
            padding: 0 0.25rem 0 0.625rem;
        }
    }
    
    span.plus, span.minus {
        display: block;
        width: 1.25rem;
        text-align: center;
        padding: 0.25rem 0;
        height: 1.25rem; 
        position: absolute;
        top: 0.375rem;
        right: 0.25rem;
        font-weight: bold;
        font-size: 1.375rem;
        cursor: pointer; 
        color: #FFF;
        background: @color-secondary;
        border-radius: 0.125rem;
        .transition(background 0.2s ease);
        
        &:hover {
            background: darken(@color-secondary, 10%);
        }
    }
    
    span.plus {
        line-height: 0.75rem;
        &:before {content: "+";}
    }
    
    span.minus {
        line-height: 0.4375rem;
        &:before {content: "-";}
    }
    
    
    
}


/****************************
*   FOOTER
*****************************/
footer {   
    .footer-content {
        color: contrast(@color-primary, #FFF, #222);
        background: @color-primary;
        padding: 50px 0; 
        font-size: 12px;
        
        a {color: inherit;}

        
        .footer-logo, 
        .footer-title, 
        .footer-contact, 
        .footer-quick-links {
            float: left;
            position: relative;
        }
        
        
        .footer-logo { 
            width: 23%;
            padding-left: 0 !important;
            
            a{display: block;}
            img {max-width: 80%; max-height:inherit;}
        }
        
        .footer-title {
            width: 24%;
            color: @color-tertiary;
            font-family: @font-secondary; 
            position:relative;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 60px;
            margin: 0;
            line-height: 1;
            padding-bottom: 10px;
            
            span {
                display: block;
                color: contrast(@color-primary, #FFF, #222);
                font-size: 22px;
                margin-top: 10px;
                
            }
            span.ada{color: contrast(@color-body);}
            
            ul {
                margin: 20px 0 0 0; 
                padding: 0; 
                list-style-type: none;
                font-size: 16px;
                color: contrast(@color-primary, #444, #FFF);
                
                li {
                    display: inline-block;       
                    
                    a {
                        display: block;
                        width: 30px;
                        height: 30px;
                        background: @color-secondary;
                        background-color:@color-secondary;
                        color: contrast(@color-secondary, darken(@color-primary, 10%), lighten(@color-tertiary, 10%));
                        border-radius: 50%;
                        line-height: 30px;
                        text-align: center;
                        .hover-scale(1.2);
                    }
                    
                }
                
                li+li {margin-left: 5px;}
            }
        }
        
        .footer-contact {
            width: 23%;
            font-family: @font-secondary;
            font-size: 14px;
            letter-spacing: 0.5px;
            
            .f-location, .f-email, .f-phone {
                position: relative;
                padding-left: 25px;
                margin-bottom: 5px; 
                &:before {
                    position: absolute;
                    font-family: @font-icon;
                    left: 0;
                    font-size: 20px;
                    top: -3px;
                    color: contrast(@color-primary, @color-link, @color-tertiary);
                }
                
                a {
                    color: inherit;
                
                    &:hover {
                        color: contrast(@color-primary, @color-link, @color-tertiary);
                    }
                }
            }
            
            .f-location {
                 text-decoration: underline;
                &:before {content: "\f041";}
                padding-top: 3px;
            }
            
            
            .f-email { 
                text-decoration: underline;
                &:before {content: "\f155"; font-size: 18px;}
            }
            .f-phone {
                a{ display: block; 
                    span {text-decoration: underline;}
                }
                .phone-attendance[href="tel:"] {display: none;}
                .phone-attendance:after{content: " (attendance)";}
                .phone-fax:after{content: " (fax)";}

                &:before {content: "\f095";}
            }
        }
        
        .footer-quick-links {
            width: 30%;
            font-weight: 600;
            font-size: 0;
            
            strong {
                display: block;
                font-family: @font-secondary;
                color: @color-tertiary;
                font-size: 22px;
                text-transform: uppercase;
                font-weight: 400;
                margin-bottom: 10px;
            }
            
            a {
                font-size: 13px;
                line-height: 2;
                color: inherit;
                
                &:hover {
                    color: contrast(@color-primary, @color-link, @color-tertiary);
                }
            }
            
            a:after {
                content: "\2022";
                color: @color-tertiary;
                padding: 0 5px;
            }
            
            a:last-child:after {display: none;}
        } 


    }
    
    .quicklinks{
         ul {
                padding: 0px;

                li{display: inline-block;

                &:before {
                    content: "\f111";
                    font-family: FontAwesome;
                    font-size: 8px;
                    padding: 6px;
                    color: @color-tertiary;
                    }
                }
             }     
        }

    .footer-quick-links a:after {
        color: @color-secondary;

    }

}

/* ---------------------
	 Copyright
	 --------------------- */

.sub-footer {
  display: block;
  background: mix(@color-body, @color-primary);
}

.copyright {
    color: contrast(@color-body, desaturate(lighten(@color-secondary, 50%), 50%), darken(@color-secondary, 50%));
    a {color: contrast(@color-body, #ffffff, #111111); white-space: nowrap;}
    a:active, a:hover {color: mix(@color-link, contrast(@color-body, #ffffff, #111111));}
}

/* ---------------------
     Non Descrimination
     --------------------- */
.nondescrimination {
    display: inline-block;
    &:hover{
            a{color: mix(@color-link, contrast(@color-body, #ffffff, #111111));}
            i{background: @color-link; color:contrast(@color-link, #111, #FFF);}
        }
    i{
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 10px;
        background: @color-tertiary;
        color: contrast(@color-tertiary, #111, #FFF); 
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        font-weight: bold;
        -webkit-transition: transform 0.1s ease;
        -moz-transition: transform 0.1s ease;
        -ms-transition: transform 0.1s ease;
        -o-transition: transform 0.1s ease;
        transition: transform 0.1s ease;
    }

}

.ada-contrast{
    display: inline-block;
    line-height: 32px;
    font-family:  @font-primary;
    
    a{
    color: #fff;
    line-height:50px;
    padding: 0px 10px;
        &:hover{
            text-decoration: underline;
        }
    }
}

/* ---------------------
	 Teacher Pages
	 --------------------- */
.teachers.homepages .content .h2 {
    font-size: 230%;
}
.teacher-home-info span {
    display: block;
    font-family: @font-tertiary;
}
.teacher-home-info {
    text-align: center;
}

.teacher-dashboard-item {
	display: block;
	color: #fff;
	padding-bottom: 50%;
	height: 200px;
	position: relative;
	margin-bottom: 20px;
	.transition(all 0.2s ease);
	&:extend(.background-cover);

	figcaption {
		&:extend(.absolute-full-bleed);
		.transition(all 0.4s ease);
		top: 70%;
		background-color: fade(@color-primary, 85%);
		padding: 10px 15px;
		h3 {
			color: #fff !important;
			font-size: 140%;
			&:after {
				content: '\f054' !important;
				font-family: @font-icon;
				position: absolute;
				right: 15px;
				top: 15px;
				font-size: 80%;
				margin: 0 !important;
				border-bottom: medium none !important;
				width: auto !important;
			}
		}
		p {
			opacity: 0;
        	height: 0;
        	margin: 0;
        	font-style: italic;
		}
	}
	
	&:hover {
		color: #fff;
		figcaption {
			top: 0;
			p {
				color: fade(#fff, 70%);
				opacity: 1;
        		height: 100%;
        		margin-bottom: 10px;
			}
		}
	}

}

.teacher-home-info span {
    display: block;
}
.teacher-home-info {
    line-height: 30px;
    text-align: center;
}
.teacher-image {
    max-width: 200px;
    width: 100%;
}

.primary-quick-links.teacher {
   border-bottom: 20px solid @color-primary;
}

