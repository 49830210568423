@media only screen and (min-width: @tablet-breakpoint) and (max-width: (@desktop-breakpoint - 1px)) {

// ==========================================================================
// Tablet Custom Styles
// ==========================================================================


/****************************
*   BASE
*****************************/


/****************************
*   UNIVERSAL
*****************************/
.media-state {
    z-index: 2; 
}


.home-col-contrast {
    position:relative;
    
    .catapultTitle {
        font-size: 40px;
    }
    
    &:before {
        width: 50%;
    }
}

.home-col-wide {
    width: 50%;
   
    & > div {
        max-width: @max-content-width * 0.50;
        padding: 0 40px;
    }
}

.home-col-narrow {
    width: 50%;
    
    & > div {
        max-width: @max-content-width * 0.50;

        padding: 0 40px;
    }
}

.breadcrumbs { text-align: center;
    .col {padding-top: 5px; padding-bottom: 5px; margin: 0;}
    .col:last-child {background:#e2e2e2;}
    .breadcrumb-quick-links {
        text-align: inherit;
    }
}

/****************************
*   TITLE BAR
*****************************/
.title-bar { 
    .logo a img {max-width: 130px;}
    .site-title h1 a{font-size:220%;}
}
    
/****************************
*   BANNER
*****************************/
.banner {
    .ccms-banner-overlay {
        right: @container-padding;
    }
}


/****************************
*   CONTENT AREA
*****************************/



/****************************
*   FOOTER
*****************************/
footer {
    .footer-content {
    
        .footer-logo {display: none;}
        .footer-title {
            width: 35%;
        }

        .footer-contact {
            width: 30%;
        }

        .footer-quick-links {
            width: 35%;
        }
        
    }
    
}
    
/*End Media Query*/
}