@media only screen and (max-width: @mobile-breakpoint) {

// ==========================================================================
// Mobile Custom Styles
// ==========================================================================

/****************************
*   BASE
*****************************/
html, body {}
main {padding-bottom: 0;}
footer{height: auto; position: relative; }
.grid {padding-left: 0px; padding-right: 0px;}

/****************************
*   UNIVERSAL
*****************************/
.media-state {
    z-index: 1; 
}

.home-col-contrast {
    position:relative;
    
    .catapultTitle {
        font-size: 40px;
    }
    
    &:before {
        display: none;
    }
}

.home-col-narrow,
.home-col-wide {
    padding-top: 30px;
    padding-bottom: 30px;
    float: none;
    width: 100%;
    /*color: #FFF;
    background-color: @color-body;*/
    
    /*&:before{background: @color-body;}*/ /*This is the white version*/
    
    & > div {
        max-width: 100%;
        padding: 0 10px;
    }
    
}

.home-col-narrow:before {
    content: " ";
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    width: 9999px;
    height: 9999px;
}

.breadcrumbs { text-align: center;
    .col {padding-top: 5px; padding-bottom: 5px; margin: 0;}
    .col:last-child {background:#e2e2e2;}
    .breadcrumb-quick-links {
        display: none;
    }
}


/****************************
*   NAVBAR
*****************************/
.navbar {
    width: 100%;
    height: 48px;
    position: fixed !important;
    right: 0;
    left: 0;
    top: 0 !important;
    z-index: 10000;
    
    /* Web Slide */
    .wsmenu-list  {
        
        & > li > a { line-height: 43px; color: #333 !important;}
        
        ul li {
            border-bottom: 1px solid #DDD;
            a {
                height: 43px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: #111 !important;
            }   
        }
        
          
        
        ul ul {border-left: none !important;}
        
        ul ul li:first-child:before  {display: none;}
        
    }
    
    .mobile-navbar-button {     
        color: #EEE;
        font-size: 1.75rem;
        float: right;
        margin-right: 10px;
        top: 3px;
        position: relative;
        margin-left: 10px;
        
        &:before {
            content:" ";
            height: 80%;
            width: 1px;
            background: #FFF;
            position: absolute;
            left: -10px;
            top: 4px;
        }
    }
    
    .mobile-logo {
        max-width: 155px;
        width: 100%;
        position: relative;
        left: 60px;
    }
    
    .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
        background: #FFF;
    }
    
    .animated-arrow.menuopen span {
        background-color: transparent;
    }
    
    .search { margin-left: 0;
        & > a {
            margin-top: 10px;
            & > i {
                color: #EEE;
                font-size: 1.75rem;
                float: right;
                margin-right: 10px;
                top: 3px;
                position: relative;
                z-index:-1;
            }
            & > span {display: none;}
        }
        
        #m2 {
            border-color: #FFF;
            border-top-right-radius: 0;
            &:before{
                right: 0;
            }
        }
    }
}


/****************************
*   HEADER
*****************************/
.header {
    padding-bottom: 20px;
}



/****************************
*   TOP BAR
*****************************/
.top-bar { 
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
    margin-top: 48px;
  
    .search {display: none;}
    
    .top-bar-social {
        display: none;
    }
    
}


/****************************
*   TITLE BAR
*****************************/
.title-bar {
    position:relative;
    min-height: 100px;
    height: auto;
       
    .logo,
    .title-links {
        position: relative;
        width: auto;
        height: auto;
        z-index: 10;
        display: none;
        
        a {
            position:relative;
            border: none;
            outline: none;
            z-index: 4; 
            top:auto;
            .transform(none);
        }
    }   
    
     .logo {
        left: 0;
        
        a { display: block; width: 105px; margin: auto;}
        a img {
            display: block; 
            max-width: 105px;
            max-height:105px;
            width: auto;
            height: auto;
        }
    }
    
    .title-links {
        right: auto;
        text-align:center;
        
        & > a {
            display: block;
            color: #FFF;
            background: transparent;
            height: auto;
            width: 100%;
            border-radius: 0;
            text-align: center;
            font-size: 0.8125rem;
            line-height: @line-height;
            text-decoration: underline;
            
            &:hover {
                background: transparent;
                color: #FFF;
                .transform(none);

                span {
                    display: block;
                    position: static;
                    top: 100%; 
                    font-size: 0.8125rem;
                    line-height: inherit;
                    color: inherit;
                    white-space:normal;
                    right: auto;
                    background: transparent;
                    padding: 0;
                    border-radius: 0;
                    margin-top: 0;
                    font-family:  inherit;
                    letter-spacing: normal;
                    -webkit-animation: none;
                            animation: none;
                    opacity: 1;
                    
                    
                    &:before {
                        display: none; 
                    }
                }
            }
            
            i {display: none;}
            span {display: block;}
            
        }
        a+a{margin-left: 0;}
        
    }
    
    .site-title {
        position:relative;
        height:auto;
        padding-left: 0;
        padding-right: 0;
        margin-top: 2%;

        h1 {
            top:auto;
            .transform(none);
            a {font-size: 60px;
            
                span {margin-top: 0;}
            }
            
        }   
    }
}

/****************************
*   QUICK LINKS
*****************************/
.mobile-quick-links {
    display: block;
    /*margin-right: -4%; Causes issues in mobile*/
}


/****************************
*   BANNER
*****************************/
//.home-page .banner,
//.home-page .banner .ccmsBanner { height: @banner-height-mobile; }

.banner {
    .homepages & {height: 0; padding-bottom: 65vw;}
    .ccms-banner-overlay {
        margin: 0px;
        right: auto;
        left: 0;
        bottom: auto;
        top: auto;
        .transform(none);
        max-width: 100%;
        width: 100%;
        height: auto;
        overflow: hidden;
        text-align: center;
        bottom: 0;
        filter: progid:DXImageTransform.Microsoft.Shadow(color=#000000,direction=45);
        text-shadow: 3px 3px 9px rgba(0, 0, 0, 0.7), -1px -1px 9px rgba(0, 0, 0, 0.7), 1px -1px 9px rgba(0, 0, 0, 0.7), -1px 1px 0 rgba(0, 0, 0, 0.7), 1px 2px rgba(0, 0, 0, 0.4);

        p { display: none;}
        
        h1 {font-size: 1.5rem;}
        
        .ccms-banner-button {text-align: center; float: none;}
    }
}


/****************************
*   CONTENT AREA
*****************************/
.primary-quick-links { display: none; }

.link-tiles {
    border-top: 1px solid #DDD;  
    
    ul.tiles {
        height: auto;
        
        li {
            width: 50%;
            height: 130px;
            float:left;

            a {
                font-size: 16px;

                &:hover {
                    div {
                        .transform(none);
                    }
                }       

                span {
                    position: absolute; 
                    bottom: 5px;
                    left: 5px;
                    line-height: 1.3;
                }
            }
        }
    }
}


/****************************
*   FOOTER
*****************************/
footer {
    text-align: center;
    
    .footer-content { 
        height: auto;
        
        .grid {
            top: auto;
            .transform(none);
        }
        
        .footer-logo, 
        .footer-title, 
        .footer-contact, 
        .footer-quick-links {
            float: none;
            position: relative;
            width: 100%;
        }
        
        .footer-quick-links {display: none;}
    
        .footer-logo {
            a {display: inline-block;}
            img {max-width: 130px; width: auto;}
        } 
        
        .footer-contact {
            .f-location,
            .f-email,
            .f-phone {
                padding-left: 0;
                &:before{display: none;}
            }
        }
          
    }
    
    .copyright span {display: block; margin-bottom: 5px; float: none !important;}
    
}
    
/*End Media Query*/
}